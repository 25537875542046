<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    :name="modalName"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper hide--in-desktop hide--in-tablet">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        {{ $t('home.consultation.button') }}
      </div>
      <section class="modal--container">
        <section-loading :show="isLoading" />
        <div class="title--text hide--in-mobile">{{ $t('home.consultation.button') }}</div>
        <form id="consultationForm" @submit.prevent="submitConsultation">
          <div class="auth--input mb-3" :class="{ 'has-error': validation.hasError('fullName') }">
            <v-text-field
              :label="$t('loginRegister.fullname')"
              outlined
              :hide-details="true"
              v-model="fullName"
              name="full_name"
              class="basic--input"
            ></v-text-field>
            <span class="val-error" v-if="validation.hasError('fullName')">{{
              validation.firstError('fullName')
            }}</span>
          </div>
          <div class="auth--input mb-3" :class="{ 'has-error': validation.hasError('email') }">
            <v-text-field
              :label="$t('loginRegister.email')"
              outlined
              :hide-details="true"
              v-model="email"
              name="email"
              class="basic--input"
            ></v-text-field>
            <span class="val-error" v-if="validation.hasError('email')">{{
              validation.firstError('email')
            }}</span>
          </div>
          <div class="auth--input mb-3" :class="{ 'has-error': validation.hasError('phone') }">
            <cleave
              id="phone"
              v-model="phone"
              :class="validation.hasError('phone') ? 'is-invalid' : ''"
              :options="phoneCleaveOption"
              autocomplete="phone"
              class="basic--input"
              type="text"
              name="phone"
              :placeholder="$t('helpDesk.agentPartner.placeholder.phone')"
            />
            <span class="val-error" v-if="validation.hasError('phone')">{{
              validation.firstError('phone')
            }}</span>
          </div>
          <div class="auth--input mb-3" :class="{ 'has-error': validation.hasError('message') }">
            <v-textarea
              :label="$t('home.consultation.message')"
              outlined
              :hide-details="true"
              v-model="message"
              name="message"
              class="basic--input"
              rows="5"
            ></v-textarea>
            <span class="val-error" v-if="validation.hasError('message')">{{
              validation.firstError('message')
            }}</span>
          </div>
          <!--          <div class="form&#45;&#45;group justify-center">-->
          <!--            <vue-recaptcha-->
          <!--              class="vue&#45;&#45;recaptcha"-->
          <!--              ref="captcha"-->
          <!--              @verify="onVerify"-->
          <!--              @expired="onExpired"-->
          <!--              :sitekey="captchaSiteKey"-->
          <!--            />-->
          <!--            <span v-show="showRecaptchaAlert" class="val-error"-->
          <!--              ><strong>{{ $t('errors.captcha') }}</strong></span-->
          <!--            >-->
          <!--          </div>-->
          <div class="">
            {{ $t('home.consultation.desc') }}
          </div>
          <button
            :disabled="isLoading"
            type="submit"
            class="btn btn-primary main--btn"
            style="margin-top: 16px"
          >
            {{ $t('home.consultation.button') }}
          </button>
        </form>
      </section>
      <button type="button" class="close hide--in-mobile" @click="close">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
import { Validator } from 'simple-vue-validator';
import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.id.js';
import { mapState } from 'vuex';
// const VueRecaptcha = () => import('vue-recaptcha');
const SectionLoading = () => import('@/components/content-loading/section-loading');
import HelperMixin from '@/mixins/helpers';

export default {
  mixins: [HelperMixin],
  components: {
    Cleave,
    // VueRecaptcha,
    SectionLoading,
  },
  data: () => ({
    modalName: 'modal--consultation',
    fullName: null,
    phone: null,
    email: null,
    message: null,
    recaptchaResponse: '',
    showRecaptchaAlert: false,
    recaptchaVerified: false,
    recaptchaExpired: false,
    isLoading: false,
    phoneCleaveOption: {
      phone: true,
      phoneRegionCode: 'id',
      noImmediatePrefix: true,
    },
  }),
  computed: {
    ...mapState({
      captchaSiteKey: (state) => state.global.captchaSiteKey,
    }),
  },
  validators: {
    fullName(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.loginRegister.fullName.required'))
        .maxLength(150, this.$i18n.t('errors.loginRegister.fullName.max', { maxChar: 150 }));
    },
    phone(value) {
      return Validator.value(value).required(this.$i18n.t('errors.phone.required'));
    },
    email(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.loginRegister.email.required'))
        .email(this.$i18n.t('errors.loginRegister.email.invalid'))
        .maxLength(255, this.$i18n.t('errors.email.max', { maxChar: 255 }));
    },
    message(value) {
      return Validator.value(value).required(this.$i18n.t('errors.message.required'));
    },
  },
  methods: {
    close() {
      this.$modal.hide(this.modalName);
      this.resetForm();
    },
    resetForm() {
      this.fullName = null;
      this.phone = null;
      this.email = null;
      this.message = null;
      this.validation.reset();
    },
    onVerify: function (response) {
      if (response) {
        this.recaptchaResponse = response;
        this.recaptchaVerified = true;
        this.showRecaptchaAlert = false;
      }
    },
    onExpired: function () {
      this.recaptchaExpired = true;
    },
    async submitConsultation() {
      const isValid = await this.$validate();
      if (isValid && !this.isLoading) {
        this.isLoading = true;
        try {
          // eslint-disable-next-line no-unused-vars
          const { data } = await this.$store.dispatch('global/submitConsultation', {
            name: this.cleanInput(this.fullName),
            phone_number: this.phone,
            email: this.cleanInput(this.email),
            message: this.cleanInput(this.message),
            // 'g-recaptcha-response': this.recaptchaResponse,
          });
          this.close();
          await this.$swal(
            this.$t('general.success'),
            this.$t('home.consultation.success'),
            'success',
          );
        } catch (e) {
          console.log('SUBMIT CONSULTATION ERROR: ', e);
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>
